<template>
    <form class="c-search-bar is-ownership-search" v-if="!state.loading" @click.stop @submit.prevent="search">
        <div class="c-search-bar__fields">
            <div class="c-search-bar__field-outer" v-if="state.allLocations.length">
                <div
                    :class="{ 'is-active': state.currentlyAmending == 'location' }"
                    @click.stop="state.currentlyAmending = 'location'"
                    class="c-search-bar__field">
                    <div class="c-search-bar__field-icon">
                        <Pin />
                    </div>

                    <span v-if="state.allLocationsSelected"> All Locations </span>

                    <span v-else-if="state.selectedLocations.length === 1">
                        {{
                            state.allLocations
                                .filter((location) => location.id === state.selectedLocations[0])
                                .map((location) => location.name)[0]
                        }}
                    </span>

                    <span v-else-if="state.selectedLocations.length > 1">
                        {{ state.selectedLocations.length }} parks selected
                    </span>

                    <span v-if="!state.selectedLocations.length"> Location </span>
                </div>
            </div>

            <div class="c-search-bar__amend" v-if="state.currentlyAmending === 'location'">
                <div class="c-amend-search is-squirrel-list">
                    <div class="c-search-bar__amend-close" @click.stop="state.currentlyAmending = false"></div>

                    <ul class="c-amend-search__select-list">
                        <li class="c-amend-search__type-option">
                            <label>
                                <input value="all" type="checkbox" v-model="allLocationsSelected" />

                                <div class="c-amend-search__squirrel">
                                    <Squirrel />
                                </div>

                                <span>All Parks</span>
                            </label>
                        </li>

                        <li
                            class="c-amend-search__type-option"
                            v-for="location in state.allLocations"
                            :index="location.id">
                            <label :for="location.id">
                                <input
                                    :id="location.id"
                                    :value="location.id"
                                    type="checkbox"
                                    v-model="state.selectedLocations" />

                                <div class="c-amend-search__squirrel">
                                    <Squirrel />
                                </div>

                                <span v-html="location.name"></span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>

            <button type="submit" class="c-search-bar__button">Search Holiday Homes For Sale</button>
        </div>
    </form>
</template>

<script setup>
    import Squirrel from "~/assets/icons/squirrel.svg";
    import { computed, onMounted } from "vue";

    import Pin from "~/assets/icons/pin.svg";

    const router = useRouter();

    const props = defineProps({
        componentData: {
            type: Object,
            required: false,
        },
    });

    const state = reactive({
        loading: true,
        allLocations: [],
        selectedLocations: [],
        currentlyAmending: false,
    });

    const allLocationsSelected = computed({
        get() {
            return state.allLocations.length > 0 && state.selectedLocations.length === state.allLocations.length;
        },
        set(value) {
            if (value) {
                state.selectedLocations = state.allLocations.map((location) => location.id);
            } else {
                state.selectedLocations = [];
            }
        },
    });

    const getHomesFilters = async () => {
        state.loading = true;

        const { data, error } = await useFetch("/api/wp/getHomesFilters");

        if (error.value) {
            console.error("Fetch error:", error.value);
        }

        if (data.value) {
            if (data.value?.locations && data.value?.locations.length > 0) {
                state.allLocations = data.value.locations;
            }
        }

        state.loading = false;
    };

    const search = () => {
        const query = {};

        if (state.selectedLocations.length) {
            query.location = state.selectedLocations.join(",");
        } else {
            state.selectedLocations = state.allLocations.map((location) => location.id);
        }

        state.currentlyAmending = false;

        const ownershipListing = document.querySelector(".js-ownership-listing");

        if (ownershipListing) {
            router.replace({
                path: "/holiday-home-ownership",
                query,
            });

            ownershipListing.scrollIntoView({
                behavior: "smooth",
            });
        } else {
            router.push({
                path: "/holiday-home-ownership",
                query,
            });
        }
    };

    await getHomesFilters();

    // Close
    onMounted(() => {
        document.body.addEventListener("click", () => {
            state.currentlyAmending = false;
        });
    });
</script>
